<template>
  <b-card>
    <b-row>
      <b-col cols="6">
        <p class="form-label">
          <strong>{{vatLabel}}</strong>
          <br>
          {{vatData}}
        </p>
      </b-col>
      <b-col cols="6">
        <p class="form-label">
          <strong>{{legalTypeLabel}}</strong>
          <br>
          {{legalTypeData}}
        </p>
      </b-col>
      <b-col cols="6">
        <p class="form-label">
          <strong>{{taxLabel}}</strong>
          <br>
          {{taxData}}
        </p>
      </b-col>
      <b-col cols="6">
        <p class="form-label">
          <strong>{{creationDateLabel}}</strong>
          <br>
          {{creationDateData}}
        </p>
      </b-col>
      <b-col cols="6">
        <p class="form-label">
          <strong>{{activityStartDateLabel}}</strong>
          <br>
          {{activityStartDateData}}
        </p>
      </b-col>
      <b-col cols="6">
        <p class="form-label">
          <strong>{{pecLabel}}</strong>
          <br>
          {{pecData}}
        </p>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import { isNotEmpty } from '@/utils/validators';
import { formatDateOnly } from '@/utils/formatterHelper';

export default {
  name: 'ProfileTabsData',
  components: {},
  props: {
    data: Object,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  computed: {
    notAvailableMsg() {
      return 'N.D.';
    },
    companyName() {
      if (this.data?.info.companyName) {
        return this.data?.info.companyName;
      }
      return this.notAvailableMsg;
    },
    vatLabel() {
      return 'P.IVA:';
    },
    vatData() {
      if (isNotEmpty(this.data?.info?.vatNumber)) {
        return this.data.info.vatNumber;
      }
      return this.notAvailableMsg;
    },
    legalTypeLabel() {
      return 'Forma giuridica:';
    },
    legalTypeData() {
      if (isNotEmpty(this.data?.info?.legalCompanyType)) {
        const denom = this.$store.getters['tableUtils/getTableList']('natura_giur');
        // console.log('denom?', denom);
        if (denom && denom.length > 0) {
          const entry = denom.find((elm) => elm.key === this.data.info.legalCompanyType);
          if (isNotEmpty(entry)) return entry.value;
        }
      }
      return this.notAvailableMsg;
    },
    taxLabel() {
      return 'Codice Fiscale:';
    },
    taxData() {
      if (isNotEmpty(this.data?.info?.taxCode)) {
        return this.data.info.taxCode;
      }
      return this.notAvailableMsg;
    },
    creationDateLabel() {
      return 'Data costituzione:';
    },
    creationDateData() {
      if (isNotEmpty(this.data?.attribute?.companyCreationDate)) {
        return formatDateOnly(this.data.attribute.companyCreationDate);
      }
      return this.notAvailableMsg;
    },
    reaLabel() {
      return 'N. REA:';
    },
    reaData() {
      let rea;
      let reaDate;
      const reaDateLabel = 'iscritta il';
      if (isNotEmpty(this.data?.attribute?.registrationNumber)) {
        rea = this.data.attribute.registrationNumber;
      }
      if (isNotEmpty(this.data?.attribute?.cciaaRegistrationDate)) {
        reaDate = `${reaDateLabel} ${this.data.attribute.cciaaRegistrationDate}`;
      }
      if (isNotEmpty(reaDate)) {
        if (isNotEmpty(rea)) {
          return `${rea} ${reaDate}`;
        }
        return reaDate;
      }
      if (isNotEmpty(rea)) return rea;
      return this.notAvailableMsg;
    },
    activityStartDateLabel() {
      return 'Data inizio attività:';
    },
    activityStartDateData() {
      if (isNotEmpty(this.data?.attribute?.startActivityDate)) {
        return formatDateOnly(this.data.attribute.startActivityDate);
      }
      return this.notAvailableMsg;
    },
    pecLabel() {
      return 'PEC:';
    },
    pecData() {
      if (isNotEmpty(this.data?.attribute?.mainPecEmail?.contactData)) {
        return this.data.attribute.mainPecEmail.contactData;
      }
      return this.notAvailableMsg;
    },
    // activityLabel() {
    //   return 'Situazione di impresa:';
    // },
    // activityData() {
    //   if (isNotEmpty(this.data?.profile?.legalAndAccountInfo?.status)) {
    //     return this.data?.profile?.legalAndAccountInfo?.status;
    //   }
    //   return this.notAvailableMsg;
    // },
  },
  mounted() {
    this.loadDenomination();
  },
  methods: {
    loadDenomination() {
      const defLanguage = 'it';
      if (!this.$store.getters['tableUtils/hasTableList']('natura_giur')) {
        if (!this.$store.getters['tableUtils/isLoadingTableList']('natura_giur')) {
          this.$store.dispatch('tableUtils/loadTableList', {
            tableName: 'natura_giur',
            language: defLanguage,
          });
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
